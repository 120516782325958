.login {
    margin: 15rem auto 4rem;
    max-width: 22rem;
    width: 100%;
    height: 100%;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
}