.retailPoint {
    display: grid;
    grid-template-areas:
        "image image image image"
        "description description description address"
        "support support support support";
    gap: .75rem 0;
}

.retailPoint:not(:last-child) {
    margin-bottom: 4rem;
}

.retailPoint.reverse {
    grid-template-areas:
        "image image image image"
        "address description description description"
        "support support support support";
}

.address {
    display: flex;
    flex-direction: column;
}

.wa {
    display: flex;
    align-items: center;
}

.support {
    grid-area: support;
}

.description {
    grid-area: description;
}

.address {
    grid-area: address;
    font-size: 1rem;
}

.image {
    grid-area: image;
    width: 100%;
    height: auto;
}

.empty {
    grid-area: empty;
}

.support, .description {
    display: flex;
    flex-direction: column;
}

.support a {
    text-decoration: none;
    color: var(--text-color);
}

@media (min-width: 768px) {
    .retailPoint {
        padding: 0 2rem;
        grid-template-areas:
        "address image image image image image image"
        "description image image image image image image"
        "support image image image image image image";
        gap: 0 2rem;
    }

    .retailPoint.reverse {
        grid-template-areas:
        "image image image image image image address"
        "image image image image image image description"
        "image image image image image image support";
        gap: 0 2rem;
    }

}