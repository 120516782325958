.banner {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 33.3vh;
    background-size: cover;
    background-position: bottom center;
    background-image: var(--background);
}

.catalog {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0;
}

.catalogBrands {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0;
}

.title {
    justify-self: end;
}

.title p, .title h1 {
    max-width: 15rem;
    text-align: right;
}

.brandLink {
    text-decoration: none;
    color: var(--text-color);
}

.brandLink:hover {
    text-decoration: underline;
}


@media (min-width: 768px) {
    .catalog {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 5fr;
        grid-template-rows: 1fr;
        gap: 2rem;
        grid-template-areas:
                "sidebar items";
        padding: 0;
        margin: 0 -2rem 2rem;
    }
}