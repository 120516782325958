.signup {
    margin: 12rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 50% auto;
    gap: 0;
    grid-template-areas: "empty title";
    padding: 0;
}

.header h1 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

.form {
    width: 100%;
    max-width: 22rem;
    margin: 0 auto;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
}


.disclaimer {
    display: flex;
    text-align: center;
    font-size: .75em;
    margin: 3em auto 0;
}

.disclaimer a {
    color: var(--accent-alt-color);
    text-decoration: none;
}

.disclaimer a:hover {
    text-decoration: underline;
}
