.contacts {
    margin: 6rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    padding: 0 1rem;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 50% auto;
    gap: 0;
    grid-template-areas: "empty title";
    padding: 0;
}

.header h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

@media (min-width: 768px) {
    .contacts {
        margin: 12rem auto 4rem;
        padding: 0 2rem;
    }
}