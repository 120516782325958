.slider {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 80vh;
    cursor: default;
    overflow: hidden;
}

.slides {
    height: 100%;
}

.slides.mounted {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.75, 0.05, 0.795, 0.035);
    transition-duration: 1s;
}

.sliderCursorLeft {
    cursor: url("../../assets/images/cursor-left.png"), auto;
}

.sliderCursorRight {
    cursor: url("../../assets/images/cursor-right.png"), auto;
}

.slide {
    background-size: cover;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    background-position: bottom left;
    position: relative;
    float: left;
}

.slide .content {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 6rem;
    left: 33%;
}

.content h2, .content p {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
}

.content p {
    font-size: 1.5rem;
}

.content p {
    margin-bottom: 1.5rem;
}

.content .aBtn {
    padding: .5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    transition: background-color .25s ease-in;
    font-weight: bold;
    font-size: 1rem;
}

.content .aBtn:hover {
    color: white;
}

.content h2 {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.55, 0.05, 0.495, 0.015);
    transition-duration: .75s;
}

@media (min-width: 768px) {
    .slide .content {
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
    }

    .content h2, .content p {
        font-size: 2rem;
    }

    .content .aBtn {
        font-size: 1.25rem;
    }

}