.documents {
    margin: 12rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 50% auto 100px;
    gap: 0;
    grid-template-areas: "empty title arrows";
    padding: 0;
}

.header h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

.content {
    padding: 0 4rem;
}