.item {
    margin: 8rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 28em auto 1fr;
    gap: 0;
    grid-template-areas:
    "image image"
    "detail detail"
    "promo promo"
    "support support"
    "description description";
}

.image {
    grid-area: image;
    position: relative;
    display: flex;
    justify-content: center;
}

.detail {
    grid-area: detail;
    padding: 0 2rem 2rem;
}

.promo {
    grid-area: promo;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 2rem;
    margin: 0 auto;
}

.support {
    grid-area: support;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    align-self: start;
}

.support .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-color);
}

.support .content .small {
    font-size: .875rem;
    margin-bottom: .25rem;
    color: var(--dark-color);
}

.supportImage {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.supportImage img {
    width: 5rem;
    height: auto;
}

.description {
    grid-area: description;
    padding: 1rem;
}

.promo > .promoFigure:not(:last-child) {
    margin-bottom: 1rem;
}

.buttons {
    display: flex;
}

.image img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
    transition: all .2s ease-in;
}

.image video {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    transition: all .2s ease-in;
}

.image .badges {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
}

.image .otherImages {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
    width: 5rem;
}

.image .otherImages div {
    border: 1px solid var(--text-color);
    width: 100%;
    opacity: .33;
    aspect-ratio: 1/1;
    cursor: pointer;
}

.image .otherImages div:not(:last-child) {
    margin-bottom: .5rem;
}

.image .otherImages div img, .image .otherImages div video {
    aspect-ratio: 1/1;
    object-fit: cover;
}

.image .otherImages div {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.image .otherImages img.play {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border: none !important;
    opacity: 1;
}

.image .otherImages .active {
    opacity: 1;
}

.image .otherImages .active img.play {
    display: none;
}

.image .badges > img {
    margin: 0;
    width: 2rem;
    height: 2rem;
}

.image .name {
    position: absolute;
    top: -2.5rem;
    /*left: 2.5rem;*/
    padding-left: 2.5rem;
    max-width: 100%;
}

.image .name h1 {
    font-size: 1.75rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 1;
}

.image .name small {
    font-weight: bold;
    text-transform: uppercase;
    font-size: .875rem;
}

.detail .code {
    font-weight: bold;
    color: var(--dark-color);
}

.detail .weight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
}

.detail .modifiers {
    display: flex;
    margin: 0;
}

.detail .components {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
}

.detail .components > li {
    margin: 0;
    font-weight: bold;
    padding-left: 1em;
    text-indent: -.7em;
}
.detail .components li:not(:last-child) {
    margin-bottom: .5rem;
}
.detail .components li::before {
    background-color: var(--accent-alt-color);
    border-radius: 50%;
    content: "";
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 2px;
    height: 6px;
    width: 6px;
}

.detail .modifiers + p {
    margin: 0;
}

.weight .number {
    padding: .5rem;
    border: 1px solid var(--text-color);
    margin-right: 1rem;
    cursor: pointer;
    transition: all .15s ease-in;
}

.weight .numberActive, .weight .number:hover {
    background-color: var(--text-color);
    color: var(--white-color);
}

.price {
    font-weight: bold;
    font-size: 1.75rem;
}

.promoFigure {
    display: flex;
    align-items: center;
    max-width: 10rem;
    padding: 0;
    margin: 0;
}

.promoFigure figcaption {
    font-size: .75rem;
    font-weight: bold;
}

.promoFigure img {
    width: 40px;
    height: 40px;
    fill: var(--text-color);
    stroke: var(--text-color);
    display: block;
    margin-right: .5rem;
}

.descriptionContent {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.tabBar {
    list-style: none;
    display: flex;
    flex-direction: row;
    text-transform: lowercase;
    font-weight: bold;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid var(--grey-color);
    position: relative;
}

.tabBar li:not(.active) {
    cursor: pointer;
    position: relative;
    display: block;
    height: calc(100% + 1px);
    margin-right: 42px;
    background: 0 0;
    flex-shrink: 1;
    min-width: 0;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: #7f7f7f;
    transition: color .3s;
}

.tabBar li.active {
    /*border-bottom: 2px solid var(--text-color);*/
}

.tabBar li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    visibility: hidden;
    background: #000;
    transition: visibility 0s .6s;
}

.tabBar > li:not(:last-child) {
    margin-right: 2rem;
}

.tab {
    display: none;
}

.activeTab {
    display: block;
}

.animatedBar {
    display: block;
    position: absolute;
    bottom: -1px;
    z-index: 1;
    height: 2px;
    background: var(--text-color);
    pointer-events: none;
    visibility: visible;
}

.animatedBar.prev {
    /*visibility: hidden;*/
    transition: left .3s,right .48s .12s, visibility 0s .6s;
}

.animatedBar.next {
    /*visibility: hidden;*/
    transition: left .48s .12s,right .3s, visibility 0s .6s;
}

.tags {
    display: flex;
    flex-direction: column;
}

.tags > .tag:not(:last-child) {
    margin-bottom: .25rem;
}

.tag {
    display: flex;
    font-size: .875rem;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}

.tagKey {
    flex-basis: 50%;
    color: #7f7f7f;
    display: flex;
    align-items: flex-start;
    padding-right: 5px;
    white-space: nowrap;
    margin: 0;
}

.tagKey span {
    padding-right: 5px;
    text-transform: lowercase;
}

.tagKey::after {
    background-image: linear-gradient(90deg,#7f7f7f 0,#7f7f7f 25%,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0));
    flex-grow: 1;
    content: "";
    display: block;
    height: 16px;
    min-width: 25px;
    width: 100%;
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    box-sizing: border-box;
}

.tagValue {
    flex-basis: auto;
    padding: 0;
    margin: 0;
}

.attrs {
    width: 100%;
    margin-right: auto;
}

.priceSale {
    text-decoration: line-through;
    color: var(--dark-color);
    font-size: 1.33rem;
    align-self: flex-end;
    margin-left: 1rem;
}

.addToCartBtn, .addToFavorite {
    padding: .75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: var(--accent-alt-color);
    border: 2px solid var(--accent-alt-color);
    outline: none;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .25s ease-in;
}

.addToCartBtn:hover, .addToFavorite:hover {
    background-color: transparent;
    color: var(--accent-alt-color);
}

.addToFavorite {
    padding: .75rem;
}

.addToFavorite img {
    width: 1.5rem;
    height: auto;
}

@media (min-width: 768px) {
    .item {
        margin: 12rem auto 4rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 34em 5em 1fr;
        grid-template-areas:
            "image image detail"
            "promo promo promo"
            "support description description";
    }

    .image .name {
        top: -2.5rem;
        right: -50%;
        transform: translate(-50%);
        width: 60%;
        padding-left: 0;
    }

    .image .name h1 {
        font-size: 2.25rem;
    }

    .detail {
        padding: 1rem 2rem;
    }

    .promo {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 0;
        margin: 0;
    }

    .promo > .promoFigure:not(:last-child) {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .support {
        justify-content: flex-end;
    }

    .attrs {
        width: 50%;
    }
}