.header {
    width: 100%;
    padding: .5rem;
    flex: 0 0 auto;
    border-bottom: 1px solid rgb(230, 230, 230);
    position: fixed;
    z-index: 999;
    background-color: var(--white-color);
    transition: .25s ease-in;
}

.header:hover {
    background-color: var(--white-color);
}

.tiny {
    background-color: var(--white-color);
}

.inner {
    display: grid;
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: .5rem;
    grid-template-areas:
        "location logo signin";
    padding: 0;
    place-content: center;
}

.tiny .inner {
    grid-template-rows: 1fr;
    grid-template-areas:
        "location logo signin";
}

.tinyWithLinks .inner {
    grid-template-rows: 1fr;
    grid-template-areas:
        "location logo signin"
}

.transparent {
    background-color: transparent;
}

.navlink {
    text-decoration: none;
    font-weight: bold;
    color: var(--text-color);
}

.links li:hover > .navlink:before {
    /*box-shadow: inset 0 -1px var(--dark-color);*/
    /*content: "";*/
    /*position: absolute;*/
    /*left: 20px;*/
    /*right: 20px;*/
    /*bottom: -2px;*/
    /*z-index: 6;*/
    /*height: 2px;*/
    /*background: #000;*/
}

.location {
    grid-area: location;
    display: flex;
    align-items: center;
}

.logo {
    grid-area: logo;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    height: 3rem;
    width: auto;
}

.signin {
    grid-area: signin;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tiny .links {
    display: none;
}

.tinyWithLinks .links {
    display: none;
}

.links {
    grid-area: links;
    justify-content: center;
    align-items: center;
    display: none;
}

.links ul, .mobileLinks ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.links ul > li:not(:last-child) {
    margin-right: 1rem;
}

.locationButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    outline: none;
    border: 0;
    cursor: pointer;
}

.locationButton img {
    height: 1.5rem;
    width: auto;
}

.login {
    display: none;
    justify-content: center;
    align-items: center;
}

.ham {
    display: block;
}

.mobileLinks {
    background-color: var(--white-color);
    position: absolute;
    top: 4.5rem;
    left: 0;
    right: 0;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.75, 0.05, 0.795, 0.035);
    transition-duration: .3s;
    transform: translateY(-150%);
}

.mobileLinks ul {
    flex-direction: column;
    padding: 0;
}

.mobileLinks ul > li {
    margin-bottom: 1rem;
}

.mobileLinksShow {
    transform: translateY(0);
}

@media (min-width: 768px) {
    .header {
        padding: 1rem 2rem 0;
    }
    .mobileLinks {
        display: none;
    }
    .login {
        display: flex;
    }
    .login img {
        height: 1rem;
        width: 1rem;
        margin-right: .15rem;
    }
    .ham {
        display: none;
    }
    .links {
        display: flex;
    }
    .tiny {
        padding-bottom: 1rem;
    }
    .tinyWithLinks .inner {
        display: grid;
    }
    .tinyWithLinks .links {
        display: flex;
    }
    .inner {
        gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "location logo signin"
            "links links links";
    }
    .tinyWithLinks .inner {
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
        "location logo signin"
        "links links links";
    }
}