.item {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: relative;
}

.itemWrapper {
    text-decoration: none;
    color: var(--text-color);
    transition: .25s ease-in;
}
.itemWrapper:hover {
    color: var(--accent-color);
}

.image {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.badges {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
}

.badges > img {
    margin: 0;
    width: 2rem;
    height: 2rem;
}

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex: 1 1 auto;
}

.price {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    flex: 0 0 auto;
}

.price > span:not(:last-child) {
    margin-right: .5rem;
}

.regularPrice, .crossedPrice {
    font-weight: bold;
    font-size: 1.15rem;
}

.regularPrice::after, .crossedPrice::after {
    content: "₽";
}

.crossedPrice {
    color: var(--dark-color);
    text-decoration: line-through;
}

.category {
    font-weight: bold;
    font-size: .75rem;
    text-align: right;
}

.name {
    font-weight: bold;
    text-align: right;
    font-size: .875rem;
}

.itemSmall {
    flex-direction: row;
    justify-content: space-between;
}

.imageSmall {
    width: 6rem;
    height: auto;
    margin-right: 1rem;
}