.sidebar {
    grid-area: sidebar;
    display: none;
}

.group:not(:last-child) {
    margin-bottom: 1rem;
}

.applyBtn {
    width: 100%;
    padding: .75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: var(--accent-alt-color);
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
}

.groupHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-height: 2.25rem;
    height: auto;
}

.group .groupHead img {
    width: auto;
    height: 1rem;
    transform: rotate(-90deg);
    transition: transform .25s ease-in;
}

.group.closed .groupHead img {
    width: auto;
    height: 1rem;
    transition: transform .25s ease-in;
    transform: rotate(90deg);
}

.group.closed .groupTags {
    display: none;
}

.groupTags {
    padding: 0;
    margin: 0;
    display: block;
}

.groupTags > .groupTag:not(:last-child) {
    margin-bottom: .25rem;
}

.groupTag {
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .sidebar {
        display: block;
    }
}