.rangeSlider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--text-color);
}
.rangeSlider label {
    color: var(--text-color);
}
.numberGroup {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: .5rem;
}
.numberInput {
    width: 50%;
    height: 2rem;
    text-align: center;
    border: 0;
    outline: none;
    font-size: 1rem;
}
.numberInput:first-of-type {
    margin-right: 7px;
}
.numberInput:last-of-type {
    margin-left: 7px;
}
.numberInput::-webkit-outer-spin-button, .numberInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.numberInput:invalid, .numberInput:out-of-range {
    border: 2px solid red;
}
.rangeGroup {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    position: relative;
    margin: 1rem 0;
}
.rangeInput {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    -webkit-appearance: none;
    width: 100%;
    border-bottom: 0;
}
.rangeInput:focus {
    outline: 0;
}
.rangeInput::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    -webkit-animation: 0.2s;
    animation: 0.2s;
    background: var(--accent-alt-color);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}
.rangeInput::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--accent-alt-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
.rangeInput::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animation: 0.2s;
    background: var(--accent-alt-color);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}
.rangeInput::-moz-range-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0 0 0 var(--text-color);
    border: 1px solid var(--accent-alt-color);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--accent-alt-color);
    cursor: pointer;
}
.rangeInput::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animation: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.rangeInput::-ms-fill-lower, .rangeInput::-ms-fill-upper {
    background: var(--accent-alt-color);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}
.rangeInput::-ms-thumb {
    z-index: 2;
    position: relative;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--accent-alt-color);
    cursor: pointer;
}

.rangeSlider,
.filter {
    margin: 0 auto;
}