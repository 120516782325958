.order {
    margin: 8rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
    grid-template-areas:
        "body"
        "detail";
}

.body {
    grid-area: body;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0;
}

.step {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;
}

.header {
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
    display: grid;
    grid-template-columns: .25fr auto;
    align-items: center;
    gap: 0;
}

.header img {
    display: none;
}

.row {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    place-items: start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    gap: 1rem;
}

.row p {
    font-weight: bold;
    font-size: 1.25rem;
    text-align: right;
    justify-self: start;
    margin: 0;
}

.place {
    display: flex;
    flex-direction: column;
}

.bonusWrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
}

.bonusColLeft {
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.bonusColRight {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.bonusColRight span {
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    margin: 0;
}

.bonusColRight p {
    font-size: 20px;
    line-height: 1.1;
    text-align: left;
    margin: 0;
}

.loyaltyCard {
    background-color: var(--accent-alt-color);
    width: 71px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loyaltyCardShadow {
    opacity: .5;
    top: 6px;
    width: 62px;
    content: "";
    position: absolute;
    left: 4px;
    z-index: -1;
    height: 100%;
    background: #000;
    background-size: cover;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    border-radius: 2px;
}

.divider {
    position: absolute;
    left: 100%;
    top: 50%;
    width: 1px;
    height: 38px;
    background: #ccc;
    transform: rotate(18deg) translateY(-50%);
}

.detail {
    grid-area: detail;
    background-color: white;
    padding: 1rem;
    align-self: start;
    display: flex;
    flex-direction: column;
}

.detailHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.detail h2 {
    margin: 0;
}

.detailProducts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 1rem 0;
}

.detailProducts img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.tags {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0;
}

.tags > .tag:not(:last-child) {
    margin-bottom: .25rem;
}

.tag {
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}

.tagKey {
    flex: 1 0 auto;
    display: flex;
    align-items: flex-start;
    padding-right: 5px;
    white-space: nowrap;
    margin: 0;
}

.tagKey span {
    padding-right: 5px;
    text-transform: lowercase;
}

.tagKey::after {
    background-image: linear-gradient(90deg,#7f7f7f 0,#7f7f7f 25%,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0));
    flex: 1 1 auto;
    content: "";
    display: block;
    height: 16px;
    min-width: 25px;
    width: 100%;
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    box-sizing: border-box;
}

.tagValue {
    padding: 0;
    margin: 0;
    flex: 0 0 auto;
}

.tagValue::after {
    content: "₽";
}

.attrs {
    width: 100%;
    margin-right: auto;
}

.tagTotal {
    margin: 2rem 0 0;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.tagTotal span:first-child {
    font-weight: bold;
    font-size: 1.15rem;
    color: var(--text-color);
}

.tagTotal span:nth-child(2) {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--text-color);
}

.discount {
    color: rgb(172, 140, 117);
}

.deliveryNotice {
    font-size: .75rem !important;
    text-align: left !important;
    margin-top: .5rem !important;
    font-style: oblique;
}

ul.suggest {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
}

ul.suggest > li {
    text-indent: 0;
    padding: 0;
    cursor: pointer;
}

ul.suggest li:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {

    .order {
        margin: 12rem auto 4rem;
        grid-template-columns: 5fr 2fr;
        gap: 2rem;
        grid-template-areas:
            "body detail";
    }

    .body {
        gap: 2rem;
        padding: 2rem 5rem;
    }

    .header {
        margin: 0;
        font-size: 1.5rem;
        font-weight: bold;
        display: grid;
        grid-template-columns: .5fr 2.5fr 3fr;
        align-items: center;
        gap: 0;
    }

    .header img {
        width: auto;
        height: 1.25rem;
        display: block;
    }

    .row {
        grid-template-columns: 10rem auto;
        grid-template-rows: auto;
        gap: 2rem;
    }
}