.miniCart {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background-color: var(--grey-color);
    z-index: 1000;
}

.inner {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cartBtn {
    display: block;
    background-color: var(--accent-alt-color);
    padding: .5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    font-size: .875rem;
    text-transform: uppercase;
    order: 1;
}

.lastItem {
    display: none;
}

.image {
    height: 4rem;
    width: 4rem;
    object-fit: cover;
}

.name {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.name span:nth-child(2) {
    font-style: italic;
}

.cart {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.price {
    font-weight: bold;
    font-size: 1.25rem;
    order: 0;
}

.price::after {
    content: "₽";
}

@media (min-width: 768px) {
    .lastItem {
        display: flex;
        align-items: center;
    }

    .cart {
        width: auto;
    }

    .cartBtn {
        order: 0;
    }

    .price {
        margin-left: 2rem;
        order: 1;
    }
}