.brand {
    display: grid;
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 1rem;
    grid-template-areas:
        "image"
        "items";
    position: relative;
}

.brandRightDirection {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "image"
        "items";
}

.image {
    grid-area: image;
    width: 100%;
    height: auto;
    cursor: pointer;
    object-fit: cover;
}

.items {
    grid-area: items;
    display: flex;
    flex-direction: column;
}

.items > *:not(:last-child) {
    margin-bottom: 1rem;
}

.brand:not(.brandRightDirection) .name {
    left: 2.25rem;
}

.name {
    position: absolute;
    font-weight: bold;
    top: -2.25rem;
    font-size: 3rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.brandRightDirection .name {
    right: 2.25rem;
}

@media (min-width: 768px) {

    .brand {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 2fr 1fr;
        grid-template-areas:
            "image items"
            "image items"
            "image items";
    }

    .brandRightDirection {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 2fr;
        grid-template-areas:
            "items image"
            "items image"
            "items image";
    }

    .image {
        height: 100%;
    }
}